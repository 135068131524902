<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
            >
              Do you want to remove this product from Deals ?
            </span>
          </v-flex>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 14px;
              "
              >{{ storage.productname }}</span
            >
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#30B868"
              @click="deleteProduct"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                Yes
              </span>
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1BD93B"
              @click="deleteDialogue = false"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                No
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap py-2>
      <v-flex xs12 sm12 md12 lg6>
        <v-layout wrap justify-start>
          <v-flex xs12 sm3 md3 lg2 xl2 pr-md-2>
            <v-img
              :src="baseURL + '/u/' + storage.photos[0]"
              aspect-ratio="1"
              contain
              height="100px"
              :alt="storage.productname"
            ></v-img>
          </v-flex>
          <v-flex xs12 sm9 md9 lg9 xl9>
            <v-layout wrap justify-center fill-height>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex lg12 text-left>
                    <router-link :to="'/Product/' + storage._id">
                      <span
                        style="
                          font-family: opensanslight;
                          font-size: 16px;
                          color: #000000;
                        "
                      >
                        {{ storage.productname }}
                      </span>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-end>
                <v-layout wrap justify-start>
                  <v-flex xs6 sm3 md3 lg3 xl3>
                    <v-layout wrap>
                      <v-flex text-left xs12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Total Stock
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span
                          style="font-family: opensansbold; font-size: 20px"
                        >
                          {{ storage.stockquantity }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm3 md3 lg3 xl3>
                    <v-layout wrap>
                      <v-flex text-left xs12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Price
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span
                          style="font-family: opensansbold; font-size: 20px"
                        >
                          {{ storage.productcost.toFixed(2) }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- <v-flex xs6 sm3 md3 lg3 xl3>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                          >Markup Price</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            font-family: opensansbold;

                            font-size: 20px;
                          "
                          >{{ storage.profitmargin }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                  <v-flex xs6 sm3 md3 lg3 xl3>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                          >Total price</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #3bc17a;
                            font-size: 20px;
                          "
                          >{{ storage.price }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex text-left xs1 text-center hidden-md-and-down>
            <v-divider vertical></v-divider>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex text-left xs12 md12 lg6>
        <v-layout wrap justify-space-between fill-height>
          <v-flex sm3 md3 lg3 xl3>
            <v-layout wrap>
              <v-flex xs6 sm12>
                <span
                  style="
                    color: #8d8d8d;
                    font-family: opensanslight;
                    font-size: 14px;
                  "
                >
                  Item added on
                </span>
              </v-flex>
              <v-flex xs6 sm12>
                <span
                  style="
                    font-family: opensanssemibold;
                    font-size: 15px;
                    color: #000000;
                  "
                >
                  {{ formatDate(storage.created_at).slice(0, 11) }}
                  <br />
                  {{ formatDate(storage.created_at).slice(11, 20) }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm3 md3 lg3 xl3>
            <v-layout wrap fill-height>
              <v-flex xs6 sm12>
                <span
                  style="
                    color: #8d8d8d;
                    font-family: opensanslight;
                    font-size: 14px;
                  "
                >
                  Available Stock
                </span>
              </v-flex>
              <v-flex xs6 sm12 text-left align-self-start>
                <span style="font-family: opensansbold; font-size: 20px">
                  {{ storage.stockquantity }}
                </span>
              </v-flex>
              <v-flex xs6 sm12 align-self-end>
                <v-btn
                  dark
                  small
                  tile
                  depressed
                  :ripple="false"
                  :color="storage.instock ? '#FFBC6E' : '#EB6359'"
                  style="text-transform: none"
                >
                  <span
                    style="font-family: poppinsregular; font-size: 14px"
                    v-if="storage.instock"
                  >
                    <v-icon>mdi-check</v-icon>
                    Available
                  </span>
                  <span
                    style="font-family: poppinsregular; font-size: 14px"
                    v-else
                  >
                    <v-icon>mdi-close</v-icon>
                    Out of Stock
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm6 md6 lg6 xl6 text-left>
            <v-layout wrap justify-center fill-height>
              <v-flex xs12 align-self-start>
                <v-layout wrap justify-start>
                  <v-flex xs12 text-left>
                    <span
                      style="
                        color: #8d8d8d;
                        font-family: opensanslight;
                        font-size: 14px;
                      "
                    >
                      Deal Type
                    </span>
                  </v-flex>
                  <v-flex text-left xs12>
                    <span
                      style="
                        font-family: opensansbold;
                        color: #3bc17a;
                        font-size: 14px;
                      "
                    >
                      <span> {{ storage.dealtype }} </span>
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-end>
                <v-layout wrap justify-center fill-height>
                  <v-flex xs8 align-self-start>
                    <v-layout wrap justify-start>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Deal Status
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #3bc17a;
                            font-size: 14px;
                          "
                        >
                          <span v-if="storage.dealapproved"> Approved </span>
                          <span v-else style="color: #30B868"> Pending </span>
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs4 text-center align-self-end>
                    <v-btn
                      tile
                      block
                      small
                      outlined
                      color="warning"
                      style="text-transform: none"
                      @click="deleteDialogue = true"
                    >
                      <span
                        style="
                          color: #000;
                          font-family: poppinsregular;
                          font-size: 14px;
                        "
                      >
                        <v-icon left>mdi-delete</v-icon>
                        Delete
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      statusLoading: false,
      deleteDialogue: false,
    };
  },
  methods: {
    changeProductStatus(item) {
      this.statusLoading = true;

      axios({
        method: "POST",
        url: "/product/status",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
          status: item.status,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Product Status Changed to " + item.status;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteProduct() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/home/deal/removebyproductid",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          productid: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.$emit("stepper", {
              getData: true,
            });
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>

<style>
div.vertical-line {
  width: 1px; /* Line width */
  background-color: #8d8d8d; /* Line color */
  height: 100%; /* Override in-line if you want specific height. */
  float: left; /* Causes the line to float to left of content. 
        You can instead use position:absolute or display:inline-block
        if this fits better with your design */
}
.statusKey.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
